import * as React from "react";
import Layout from "../components/global/Layout";
import Body from "../components/client-referral/Body";
import Form from "../components/client-referral/Form";
import { useClientReferralsQuery } from "../hooks/useClientReferralsQuery";
import Helmet from "react-helmet";

const ClientReferralPage = () => {
    const data = useClientReferralsQuery();
    return (
        <Layout>
            <Body data={data.clientReferralsFields} />
            <Form data={data.clientReferralsFields} />
            <Helmet>
                <title>{data.clientReferralsMetaData.metaTitle}</title>
                <meta name="description" content={data.clientReferralsMetaData.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default ClientReferralPage;
import * as React from "react";

const Body = ({ data }) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height:"90px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1541635043808290" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget " style={{textAlign:"center"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1541635076096299" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1541635076096299_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines">&nbsp;</h2>
                            <h2 className="headlines">&nbsp;</h2>
                            <h1 className="headlines" style={{fontWeight: "bold", fontFamily: "BrutalType, sans-serif"}} dangerouslySetInnerHTML={{__html: data.title}}></h1>
                            <div dangerouslySetInnerHTML={{__html: data.description}}></div>
                            </span></div>
                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1541636000863358" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;
import { useStaticQuery, graphql } from "gatsby"

export const useClientReferralsQuery = () => {


    const data = useStaticQuery(graphql`
    query ClientReferralQuery {
        wpPage(title: {eq: "CLIENT REFERRAL"}) {
          ACF_ClientReferralsPage {

            clientReferralsFields {
                title
                description
                form
            }

            clientReferralsMetaData {
                metaTitle
                metaDescription
            }
        
        }
        }
    }     
    `)
    return data.wpPage.ACF_ClientReferralsPage;
}